(function () {
    $('.filter-open').on('click', function(){
        $('.catalog-filter').addClass('active')
    })
    $('.filter-close').on('click', function(){
        $('.catalog-filter').removeClass('active')
    })

    if(!$('#filter-form').length){return}
        checkFilters()

    $('#filter-form').on('change', function(){
        checkFilters()
    })

    function checkFilters() {
        let $submitBtn = $('#filter-form .filter-submit')
        let checkedInputs = $('#filter-form input:checked')
        if(!checkedInputs.length){
            $submitBtn.attr('disabled', 'disabled')
        } else {
            $submitBtn.removeAttr('disabled')
        }
    }

})()