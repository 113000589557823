(function() {
    const $header = $('.page-header');
  
    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
  
    const sticky = $header.offset().top + $('.main-header__top').height();
  
    function stickyHeader() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
  
      if (st > lastScrollTop) {
        // downscroll code
        $header.removeClass('scrolled--visible');
      } else {
        // upscroll code
        $header.addClass('scrolled--visible');
      }
  
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  
      $header.toggleClass('scrolled', window.pageYOffset > sticky);
  
      if (window.pageYOffset <= sticky) {
        $header.removeClass('scrolled--visible scrolled');
      } 
      if ($(window).width() > 1150) {
        let subNavTop = $('.main-nav').height() - window.pageYOffset + $('.main-nav').offset().top
            $(".sub-nav-content").css("top", subNavTop +"px");
            $(".sub-nav-bg").css("top", subNavTop +"px");
            $(".main-nav__list li:nth-child(1) .sub-nav-first").css("top", subNavTop +"px");
      } else {
        $(".sub-nav-content").removeAttr('style');
        $(".sub-nav-bg").removeAttr('style');
        $(".main-nav__list li:nth-child(1) .sub-nav-first").removeAttr('style');
      }
    }
  
    $(window).on('scroll load resize', stickyHeader);
  })();