(function($) {
    $(function() {
   
      $('.tab-nav').on('click', '.tab-nav-item:not(.active)', function() {
       var container = $(this).closest('.tab-nav').attr('data-nav')
        $(this)
        .addClass('active').siblings().removeClass('active');
        $('.'+ container).find('.tab').removeClass('active').eq($(this).index()).addClass('active');
      });
   
      var tabIndex = window.location.hash.replace('#tab','')-1;
      if (tabIndex != -1) $('ul.property-nav__list li').eq(tabIndex).click();
   
      $('a[href*="#tab"]').on('click', function() {
        var tabIndex = $(this).attr('href').replace(/(.*)#tab/, '')-1;
        console.log(tabIndex);
        $('ul.property-nav__list li').eq(tabIndex).click();
      });
   
    });
   })(jQuery);