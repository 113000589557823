(function () {
  $('.burger-menu').on('click', function(){
    if($('.main-nav').hasClass('active')) {
      $('.main-nav').removeClass('active');
      $('.burger-menu').removeClass('active');
      $('body').removeClass('lock')
    } else {
      $('.main-nav').addClass('active');
      $('.burger-menu').addClass('active');
      $('body').addClass('lock')
    }
  })
  let link = $('.main-nav__link')
  link.on('click', function(e){
    if ($(this).next('.sub-nav-first').length){
      e.preventDefault()
      $(this).next('.sub-nav-first').toggleClass('active')
    }
  })
  let arrow = $('.sub-arrow')
  arrow.on('click', function(e){
    e.preventDefault()
    $(this).toggleClass('active')
    $(this).parent().find('.sub-nav-ul').toggleClass('active')
  })
})();