(function () {
  var swiper1 = new Swiper(".hero-slider", {
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".hero-slider__next",
      prevEl: ".hero-slider__prev",
    },
    speed: 1000,
    allowTouchMove: false,
    reverseDirection: true,
    // autoplay: {
    //   delay: 3000,
    // },
  });
  var swiper1nested = new Swiper(".cards-slider", {
    effect: "coverflow",
    grabCursor: true,
    spaceBetween: 20,
    centeredSlides: true,
    slidesPerView: "auto",
    loop: true,
    coverflowEffect: {
      rotate: 0,
      stretch: 150,
      depth: 200,
      modifier: 1,
      slideShadows: true,
  },
  breakpoints: {
    1365: {
      coverflowEffect: {
        stretch: 220,
    },
    },
  },
  speed: 1000,
      autoplay: {
      delay: 3000,
    },
  });

  var swiper2 = new Swiper(".offer-slider", {
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl: ".offer-slider__next",
      prevEl: ".offer-slider__prev",
    },
    speed: 600,
    breakpoints: {
      350: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
      1400: {
        slidesPerView: 6,
        spaceBetween: 30,
      }
    },
    // speed: 1000,
    // autoplay: {
    //   delay: 3000,
    // },
  });
  scrollToFirst('offer-slider__next', swiper2)
  var swiper3 = new Swiper(".exhibition-slider", {
    slidesPerView: 'auto',
    spaceBetween: 20,
    navigation: {
      nextEl: ".exhibition-slider__next",
      prevEl: ".exhibition-slider__prev",
    },
    loop: true,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
    speed: 1000,
    autoplay: {
      delay: 3000,
    },
  });

  var swiper4 = new Swiper(".systems-slider", {
    slidesPerView: 'auto',
    spaceBetween: 16,
    navigation: {
      nextEl: ".systems-slider__next",
      prevEl: ".systems-slider__prev",
    },
    loop: true,
    breakpoints: {
      576: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
    speed: 1000,
    autoplay: {
      delay: 3000,
    },
  });
  var swiper5 = new Swiper(".popular-slider", {
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl: ".popular-slider__next",
      prevEl: ".popular-slider__prev",
    },
    speed: 600,
    breakpoints: {
      350: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
      1400: {
        slidesPerView: 6,
        spaceBetween: 30,
      }
    },
  });
  scrollToFirst('popular-slider__next', swiper5)
  var swiper6 = new Swiper(".article-slider", {
    slidesPerView: 'auto',
    spaceBetween: 20,
    navigation: {
      nextEl: ".article-slider__next",
      prevEl: ".article-slider__prev",
    },
    speed: 600,
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      991: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  });
  scrollToFirst('article-slider__next', swiper6)
  var swiper7 = new Swiper(".review-slider", {
    slidesPerView: 'auto',
    spaceBetween: 20,
    navigation: {
      nextEl: ".review-slider__next",
      prevEl: ".review-slider__prev",
    },
    loop: true,
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
        speed: 1000,
    autoplay: {
      delay: 3000,
    },
  });
  var swiper8 = new Swiper(".nav-slider", {
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl: ".nav-slider__next",
      prevEl: ".nav-slider__prev",
    },
    speed: 1000,
  });
  scrollToFirst('nav-slider__next', swiper8)
  function scrollToFirst(button, slider){
    var changed = false;
    $("."+button).on('click', function () {
        if (changed === true) {
            changed = false;
            slider.slideTo(0);
        }
        if (slider.isEnd) changed = true;
    })
  }

})();

