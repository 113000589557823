(function () {
  if (!$(".accordion").length) {
    return;
  }
  $(".accordion .inner").hide();
  $(".accordion .trigger").on("click", function () {
    let $this = $(this);
    $this.hasClass("active")
      ? $this.removeClass("active").next().stop().slideUp(200)
      : $this.addClass("active").next().stop().slideDown(200);
  });
})();
