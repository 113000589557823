(function () {
      $('.form__field').on('focus', function() {
        $(this).closest('.form__group').find('.palceholder-js').addClass('hide');
      });
      $('.form__field').on('blur', function() {
        var $this = $(this);
        if ($this.val().length == 0) $(this).closest('.form__group').find('.palceholder-js').removeClass('hide');
      });
      $('.contact-form__btn').on('click', function() {
        checkPlaceholder()
      });
      function checkPlaceholder(){
        $('.form__field').each(function(){
          let $this = $(this);
          let $palceholder = $this.closest('.form__group').find('.palceholder-js')
          if(!$palceholder.length) {return}
          if ($this.val().length == 0) {
            $palceholder.removeClass('hide');
          } else {
            $palceholder.addClass('hide');
          }
        })
      }
      $('.minus').on('click', function () {
        let $input = $(this).parent().find('input');
        let count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $input.change();
        return false;
      });
      $('.plus').on('click', function () {
        let $input = $(this).parent().find('input');
        $input.val(parseInt($input.val()) + 1);
        $input.change();
        return false;
      });
})();
